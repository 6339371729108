.dialog.archive {
  padding: 0;

  .icon-checkmark,
  .icon-close {
    fill: #fff;
  }

  .mobile-menu-active {
    .archive-dialog-body {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgb(0 0 0 / 50%);
      }
    }
  }

  .archive-upload {
    margin: 10px 0;

    .drop-zone {
      border: 2px dashed #fff;
      background-color: #707983;
      text-align: center;

      > div {
        padding: 10px 0;
      }
    }
  }

  .confirm-delete-wrapper {
    height: 44px;
    padding: 0 12px;
    border-bottom: 2px solid #272727;

    .title {
      padding-right: 12px;
      font-weight: bold;
      text-align: right;
    }

    .button {
      width: auto;
      height: auto;

      &.confirm-trash {
        margin-right: 8px;
      }
    }

    .button__inner {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .button__icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border: solid 1px #fff;
      border-radius: 50%;
    }

    .button__text {
      padding-top: 3px;
      padding-left: 4px;
    }
  }

  .header-divider {
    hr {
      height: 2px;
      margin: 0;
      background-color: #172026;
    }
  }

  .archive-dialog-body {
    padding: 0 12px 12px;
  }

  .archive-dialog-body-text {
    padding-top: 15px;
  }

  @include breakpoint($breakMedium) {
    .confirm-delete-wrapper {
      height: 60px;
      padding-right: 20px;

      .title {
        font-size: 18px;
        font-weight: normal;
      }

      .button.confirm-trash {
        margin-right: 16px;
      }

      .button__text {
        padding-left: 6px;
      }
    }

    .archive-dialog-body {
      padding: 0 20px 20px;
    }
  }

  .list-container {
    @extend %overflow-vertical;

    position: relative;
    min-height: 236px;
    max-height: 336px;
    padding-top: 15px;
    padding-right: 15px;

    > div:nth-of-type(1) {
      hr {
        display: none;
      }
    }
  }

  .archive-warning {
    padding-top: 10px;

    p {
      margin: 0;
    }

    .help-icon {
      margin-left: 10px;
    }

    @include breakpoint($breakMedium) {
      .help-icon {
        margin-left: 30px;
      }
    }
  }

  $bet-cell-bet-type-width: 99px;

  .fgrid--archive-item {
    margin-bottom: 4px;
    @include joker-transition(0.2s, opacity);

    hr {
      margin-bottom: 16px;
    }

    &.delete-selected {
      opacity: 0.5;
    }

    h2 {
      margin: 0;
    }

    .fgrid-cell-bet-type {
      display: flex;
      flex-basis: $bet-cell-bet-type-width;
      justify-content: center;
    }

    .fgrid-cell-info {
      color: #b8c4cc;
      font-size: 11px;
    }

    .fgrid-cell-button {
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }

    .bet-type-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      height: 60px;
      margin: 0 12px;
      font-family: 'Arial', sans-serif;
      font-size: 24px;
      font-weight: bold;

      &--bomben {
        font-size: 14px;
      }

      &--big9 {
        font-size: 22px;
      }

      &--trippel {
        font-size: 16px;
      }
    }

    &--bet {
      .fgrid-cell-info {
        flex-basis: calc(99.999% - #{$bet-cell-bet-type-width});
        flex-shrink: 0;
      }

      .fgrid-cell-button {
        flex-basis: 25%;
        flex-shrink: 0;
      }

      @include breakpoint($breakSmall) {
        flex-wrap: nowrap;

        .fgrid-cell-info {
          flex-basis: auto;
          flex-shrink: 1;
        }

        .fgrid-cell-button {
          flex-basis: 62px;
          flex-grow: 0;
          margin-top: 0;
        }
      }
    }

    &--template {
      $buttonWidth: 62px;

      flex-wrap: nowrap;

      .fgrid-cell-info {
        flex-basis: auto;
        flex-shrink: 1;
      }

      .fgrid-cell-button {
        flex-basis: $buttonWidth;
        flex-grow: 0;
      }
    }

    a.item-button__label {
      text-decoration: none;
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    padding-left: 5px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }

  .item-button {
    transition: opacity 0.15s ease;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border: 1px solid #fff;
      border-radius: 50%;
    }

    &__label {
      margin-top: 3px;
    }

    @include hover {
      opacity: $hover-opacity;
    }

    .icon-checkmark {
      width: 24px;
      height: 24px;
    }
  }
}
