.conditions-wrapper {
  $headerColumnSmall: 100%;
  $headerColumnLarge: 160px;

  background-color: #272727;
  color: #fff;

  &__tab {
    display: inline-block;
    margin-top: 20px;
    padding: 11px 10px;
    border-left: solid 3px #0e82ff;
    background: #2e4855;
    font-size: 10px;
    @extend %fontSemiBold;
  }

  &__content-outer {
    padding: 20px 0;
    background: #2e4855;
  }

  .conditions-wrapper__content-outer + .conditions-wrapper__content-outer {
    padding-top: 0;
  }

  &__content-inner {
    padding: 14px;
    background-color: #273f4a;
    font-size: 11px;
  }

  .conditions-group-disclaimer {
    margin: 14px 14px 0;
    font-size: 10px;
    line-height: 1.4;
  }

  .conditions-group-title {
    margin: 0 0 12px;
    font-size: 14px;
    font-weight: normal;
  }

  .label-before {
    display: inline-block;
    margin-right: 8px;
  }

  input + .label-before,
  .select-input + .label-before {
    margin-left: 16px;
  }

  input + .label-before,
  .select-input + .label-before {
    margin-left: 16px;
  }

  input + .label-after,
  .select-input + .label-after {
    margin-left: 8px;
  }

  .label-after + .label-before {
    margin-left: 16px;
  }

  .conditions-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 22px;

    &--rhythm-small {
      margin-bottom: 12px;
    }

    &--rhythm-medium {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .header-column {
      display: inline-flex;
      flex: 0 0 $headerColumnSmall;
      align-items: center;
      margin-bottom: 10px;
      font-size: 12px;
      @extend %fontBold;
    }
  }

  .conditions-count-button {
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &__inner {
      display: flex;
      align-items: center;
    }

    &__sign {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 6px;
      border: solid 1px #fff;
      border-radius: 50%;
      font-size: 14px;
      @extend %fontSemiBold;
    }
  }

  .point-intervals-item {
    margin-bottom: 12px;

    .conditions-row:nth-child(n + 2) {
      // Hack to align with fields in first row
      margin-left: 26px;
    }

    .conditions-row {
      margin-bottom: 0;
    }

    .conditions-row + .conditions-row {
      margin-top: 6px;
    }
  }

  .base-conditions-minmax-row {
    .label-after {
      flex: 1 0 100%;
      margin-top: 6px;
      margin-left: 0;
    }
  }

  .base-conditions-comment-row {
    .label-before,
    input {
      flex: 1 0 100%;
    }

    .label-before {
      margin-bottom: 4px;
    }
  }

  .help-icon {
    width: 16px;
  }

  @include breakpoint($breakMedium) {
    &__tab {
      padding: 12px 13px;
      font-size: 14px;
    }

    &__content-inner {
      padding: 20px;
      font-size: 14px;
    }

    .conditions-group-title {
      margin-bottom: 14px;
      font-size: 18px;
    }

    .label-before {
      margin-right: 10px;
    }

    input + .label-after,
    .select-input + .label-after {
      margin-left: 10px;
    }

    input + .label-before,
    .select-input + .label-before {
      margin-left: 26px;
    }

    .conditions-group-disclaimer {
      font-size: 12px;
    }

    .conditions-row {
      margin-bottom: 30px;

      &--rhythm-small {
        margin-bottom: 16px;
      }

      &--rhythm-medium {
        margin-bottom: 20px;
      }

      .header-column {
        flex-basis: $headerColumnLarge;
        margin-bottom: 0;
      }
    }

    .point-intervals-item {
      display: flex;

      .conditions-row + .conditions-row {
        margin-top: 0;
      }
    }

    .base-conditions-minmax-row {
      .label-after {
        flex: none;
        margin-top: 0;
        margin-left: 26px;
      }
    }

    .base-conditions-comment-row {
      .label-before,
      input {
        flex: none;
      }

      .label-before {
        margin-right: 12px;
        margin-bottom: 0;
      }

      input {
        width: 385px;
      }
    }
  }

  .f-reduction-info {
    display: flex;
    align-items: center;
    max-width: 480px;
    margin-top: 20px;
    margin-left: 0;
    padding: 12px 18px 12px 0;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    line-height: 1.3em;
    @extend %fontSemiBold;

    & > svg {
      flex: 0 0 18px;
      margin-right: 12px;
      fill: #fff;
    }
  }
}
