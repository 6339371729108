.systemtitle {
  flex: 1 0 auto;
  overflow: hidden;

  .icon-share {
    width: 12px;
    height: 12px;
    fill: #fff;
  }

  .button-content {
    max-width: 105px;
    margin-left: auto;
    line-height: 1.285;
    text-align: right;

    .icon-shared-wrapper {
      padding-right: 4px;
    }

    &__input {
      height: auto;
      padding: 0;
      border: 0;
      border-bottom: 1px #fff dashed;
      outline: none;
      background: none;
      color: #fff;
      text-align: right;
      text-indent: 0;
      @extend %fontBold;
    }

    &__name {
      padding-bottom: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @extend %fontBold;
    }

    &__event {
      overflow: hidden;
      font-size: 9px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .icon-system-title-menu {
    width: 18px;
    height: 18px;
    fill: #fff;
  }

  .system-popup {
    width: 280px;

    .action-button,
    .inline-settings {
      width: 100%;
      padding: 0 16px;
      background-color: #1a1a1a;
    }

    .action-button {
      display: block;
      height: 40px;
      transition: background-color 0.25s;
      font-size: 14px;

      & + .button {
        position: relative;
        height: 41px;
        padding-top: 1px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 16px;
          left: 16px;
          height: 1px;
          background-color: #272727;
        }
      }

      &:hover {
        background: darken(#1a1a1a, 5);
      }

      &__inner {
        display: flex;
        align-items: center;
      }

      &__icon {
        flex: none;
      }

      &__text {
        flex: 1;
        padding-left: 10px;
        font-weight: normal;
        text-align: left;
      }
    }

    .inline-settings {
      padding-bottom: 16px;
    }
  }

  .mobile-bet-info {
    padding: 16px;
  }

  @include breakpoint(360px) {
    .button-content {
      max-width: 130px;
    }
  }

  @include breakpoint(410px) {
    .button-content {
      max-width: 170px;
    }
  }

  @include breakpoint($breakSmall) {
    flex: none;

    .button-content {
      max-width: 120px;
    }

    .system-popup {
      width: 210px;
    }

    .mobile-bet-info {
      display: none;
    }

    .system-popup .button--moreinfo {
      display: none;
    }
  }

  @include breakpoint($breakMedium) {
    .button-content {
      max-width: 190px;
    }
  }

  @include breakpoint($breakLarge) {
    .button-content {
      max-width: 220px;
      font-size: 14px;

      &__event {
        font-size: 12px;
      }
    }
  }
}
