html,
body {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: $bodyDefaultBackground;
  -webkit-font-smoothing: antialiased;
  user-select: none;

  // Enable faster clicks on touch devices and prevent double tap
  // zoom on iOS (which conflicts with double tab to select horses)
  touch-action: manipulation;
}

// Allow text selection on article pages and calendar editorial blocks
body.body-article,
body.body-calendar .editorial,
body.body-sport .editorial {
  user-select: text;
}

// Restore opinionated default styles that has been removed from normalize.css
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

// Restore opinionated default styles that has been removed from normalize.css
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit;
}

// Fix for IE11
main {
  display: block;
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

select[disabled],
select:disabled {
  &,
  & option {
    color: #b0b0b0 !important;
  }
}

input:not([type='radio']),
select {
  border: 0;
  color: #3f3f3f;
}

input[type='text'],
input[type='number']:not(.form-control),
input[type='email'],
input[type='password'],
input[type='url'] {
  height: 25px;
  background-color: #fff;
  text-indent: 6px;
}

input::placeholder {
  color: #999;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
.text-button {
  @extend %textLink;

  &.match-text {
    color: $bodyText;
  }
}

body {
  box-sizing: border-box;
  color: $bodyText;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

#app {
  height: 100%;
}

// iOS: Fix click event bubbling
// See http://gravitydept.com/blog/js-click-event-bubbling-on-ios
.current-input-touch * {
  cursor: pointer;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.page-top {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
}

// TODO - revisit when all old template based atg dialogs has been ported over
body > .overlay,
#app > .overlay,
.dialogRootContainer .overlay {
  position: fixed;
  z-index: 200;

  &.dialog-overlay {
    z-index: 204;
  }

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.grey {
    background-color: rgb(0 0 0 / 60%);
  }

  &.solid-grey {
    background-color: #272727;
  }
}

.dialogRootContainer .overlay {
  animation: fadeIn 0.2s linear;
}
